























import { computed } from '@vue/composition-api';

export default {
  props: {
    minHours: {
      type: Number,
      default: 100
    },
    maxHours: {
      type: Number,
      default: 240
    }
  },
  setup(props, ctx) {
    const minHoursVal = computed({
      get: () => props.minHours,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const maxHoursVal = computed({
      get: () => props.maxHours,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    return { minHoursVal, maxHoursVal };
  }
};
